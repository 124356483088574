<template>
  <div class="providers-buttons">
    <CLink @click="logInWithFacebook">
      <CIcon class="mr-2" :src="require('@/assets/social_auth/icon-facebook.svg')" alt="Facebook Account"/>
      <span v-if="allowRegister" v-translate>Sign up with Facebook</span>
      <span v-translate v-else>Login with Facebook</span>
    </CLink>
    <CLink @click="logInWithGoogle">
      <CIcon class="mr-2" :src="require('@/assets/social_auth/icon-google.svg')" alt="Google Account"/>
      <span v-if="allowRegister" v-translate>Sign up with Google</span>
      <span v-translate v-else>Login with Google</span>
    </CLink>
    <CLink @click="logInWithApple">
      <CIcon class="mr-2" :src="require('@/assets/social_auth/icon-apple.svg')" alt="Apple Account"/>
      <span v-if="allowRegister" v-translate>Sign up with Apple</span>
      <span v-translate v-else>Login with Apple</span>
    </CLink>
    <CAlert color="danger" v-if="authError">
      {{ messageError }}
    </CAlert>
  </div>
</template>
<script>


import {loadInitFacebookSDK} from "@/utils/social_oauth/facebook.js";
import {loadInitAppleSDK} from "@/utils/social_oauth/apple.js";
import {loadInitGoogleSDK, loginGoogleUser} from "@/utils/social_oauth/google.js";
import {mapActions} from "vuex";
import {computed, methods} from "@/domain/login/mixin";


export default {
  name: 'SocialLogin',

  data() {
    return {
      authError: false,
      messageError: 'Login error'
    }
  },

  props: {
    allowRegister: {
      type: Boolean,
      default: false
    }
  },

  async mounted() {
    await loadInitFacebookSDK()
    await loadInitAppleSDK()
    await loadInitGoogleSDK()
  },

  computed: {
    ...computed,
  },

  methods: {
    ...methods,
    ...mapActions('login', [
      'socialLogin',
    ]),
    async doLogin(backend, token) {
      try {
        this.authError = false
        let params = {}
        params['backend'] = backend
        params['token'] = token
        params['grant_type'] = "convert_token"
        params['client_id'] = process.env.VUE_APP_OAUTH_CLIENT_ID
        params['is_dashboard'] = true
        if (this.allowRegister)
          params['allow_register'] = true
        await this.socialLogin(params)
        await this.afterLogin()
      } catch (e) {
        if (await this.manageLoginError(e, this.email)) return
        console.log( 'after catch?')
        this.authError = true
      }
    },
    displayError(message) {
      this.authError = true
      this.messageError = message
      return true
    },
    async logInWithGoogle() {
      const response = await loginGoogleUser()
      await this.doLogin("google-oauth2", response.access_token)
    },

    async logInWithApple() {
      try {
        const authResponse = await window.AppleID.auth.signIn()
        if (authResponse && authResponse.authorization) {
          await this.doLogin("apple-id", authResponse.authorization.id_token)
        }
      } catch (error) {
        console.log('Apple Id error', error)
        this.authError = true
      }
    },


    logInWithFacebook() {
      console.log('FB login')
      this.authError = false
      const params = {scope: 'email'}
      window.FB.login(response => {
        if (response && response.authResponse) {
          window.FB.api(`/${response.authResponse.userID}?fields=email`, async userResponse => {
            console.log('FB userResponse', userResponse);
            if (userResponse && userResponse.email) {
              await this.doLogin("facebook", response.authResponse.accessToken)
            } else {
              // revoke app permissions
              window.FB.api(`/${response.authResponse.userID}/permissions/`, 'delete', response => {
                console.log('FB revoke app permissions', response);
                this.authError = true
              });
            }
          }, params);
        }
      }, params)
      return false;
    }
  }
}
</script>
