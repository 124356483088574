<template>
  <div class="auth__head d-flex flex-column justify-content-center">
    <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="32"
        viewBox="0 0 136 34"
    />
    <h1 class="font-weight-semi-bold">{{ title }}</h1>
  </div>
</template>
<script>
export default {
  name: "OnBoardingHeader",
  props: {
    title: {
      type: String,
      required: false
    }
  }
}
</script>


<style scoped>

</style>