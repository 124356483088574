import {mapActions, mapGetters} from "vuex";

export const methods = {
    ...mapActions('login', [
        'validateInvitation',
        'acceptInvitation',
    ]),
    ...mapActions('events', [
        'rememberUrl'
    ]),
    async checkQueryParams() {
        this.loading = true
        await this.checkRegionAndPlan()
        await this.checkInvitation()
        this.loading = false
    },
    async checkRegionAndPlan() {
        if (this.$route.query.region || this.$route.query.plan) {
            await this.rememberUrl({
                region: this.$route.query.region,
                plan: this.$route.query.plan
            })
        }
    },
    async checkInvitation() {
        if (this.$route.query.code && this.$route.query.type) {
            await this.validateInvitation({
                code: this.$route.query.code,
                type: this.$route.query.type
            })
        }
    },
    async afterLogin() {
        if (this.invitation)
            await this.$router.push({name: 'login.accept'})
        else
            await this.$router.push({name: 'eventList'})
    },
    async manageLoginError(e, email) {
        if (e.message.code === 'user_require_validation')
            return await this.$router.push({name: 'login.validate', params: {email: email}})
        if (e.message.code === 'user_not_allowed_in_dashboard')
            return await this.displayError('Access in dashboard is not allowed')
        if (e.message.code === 'registration_not_allowed')
            return await this.displayError('Registration is not allowed')
        if (e.message.error === 'access_denied')
            return await this.displayError('User not found')
        if (e.message.non_field_errors)
            return await this.displayError(JSON.stringify(e.message.non_field_errors[0]))
        if (e.message.email)
            return await this.displayError(JSON.stringify(e.message.email[0]))
        else if (e.message.password)
            return await this.displayError(JSON.stringify(e.message.password))
        return false
    },
    displayError(message) {
        this.error = message
        return true
    },
}

export const computed = {
    ...mapGetters('login', [
        'isAuthenticated',
        'invitation',
    ]),
}

