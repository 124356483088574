const googleClientId = process.env.VUE_APP_GOOGLE_APP_ID

export const loadInitGoogleSDK = () => {
  // load the sdk
  const script = document.createElement("script");
  script.src = "https://accounts.google.com/gsi/client"
  script.async = true
  script.defer = true
  script.onload = initGoogleGIS
  document.body.appendChild(script)
};


const initGoogleGIS = async () => {
    window.google.accounts.id.initialize({
        client_id: googleClientId,
        auto_select: true
    })
}

export const loginGoogleUser = async () => {
  console.log('Google loginGoogleUser')
  const client = window.google.accounts.oauth2.initTokenClient({
    client_id: googleClientId,
    scope: `profile email`,
    callback: "", // defined at request time
  });
  return await new Promise((resolve, reject) => {
    try {
      // Settle this promise in the response callback for requestAccessToken()
      client.callback = (resp) => {
        if (resp.error !== undefined) {
          reject(resp);
        }
        console.log("Google client resp", resp);
        resolve(resp);
      };
      console.log("Google client", client);
      client.requestAccessToken({prompt: "consent"});
    } catch (err) {
      console.log('Goggle loginGoogleUser error',err);
    }
  });
};





