const appleClientId = process.env.VUE_APP_APPLE_APP_ID

export const loadInitAppleSDK = () => {
    // load the sdk
    const script = document.createElement("script")
    script.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
    script.async = true
    script.defer = true
    script.onload = initApple
    document.body.appendChild(script)
};


const initApple = async () => {
    window.AppleID.auth.init({
        clientId: appleClientId, // This is the service ID we created.
        scope: "name email", // To tell apple we want the user name and emails fields in the response it sends us.
        redirectURI: location.origin, // As registered along with our service ID
        state: '', // Any string of your choice that you may use for some logic. It's optional and you may omit it.
        usePopup: true, // Important if we want to capture the data apple sends on the client side.
    });
}