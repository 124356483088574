const facebookClientId = process.env.VUE_APP_FACEBOOK_APP_ID

export const initFacebook = async () => {
    window.fbAsyncInit = function () {
        window.FB.init({
            appId: facebookClientId,
            cookie: true,
            xfbml: true,
            version: 'v15.0'
        });
    };
}

export const loadInitFacebookSDK = () => {
    // load the sdk
    const script = document.createElement("script")
    script.src = "https://connect.facebook.net/en_US/sdk.js"
    script.async = true
    script.defer = true
    script.onload = initFacebook
    document.body.appendChild(script)
};