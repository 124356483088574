<template>
   <CContainer fluid class="auth">
    <CRow class="auth__cols">
      <CCol lg="7" class="col-1-decoration">
        <div class="d-flex flex-column justify-content-center auth__col-1" :style="{maxWidth: `${maxContentWidth}px`}">
          <slot/>
        </div>
        <CRow class="mt-3">
          <CCol>
            <TheFooter/>
          </CCol>
        </CRow>
      </CCol>
      <CCol lg="5" class="d-none d-lg-flex has-circles">
        <div class="d-flex flex-column justify-content-center auth__col-2">
          <CRow>
            <CCol>
              <div class="pay-off">
                <div class="pay-off__1">Capture.</div>
                <div class="pay-off__2">Engage.</div>
                <div class="pay-off__3">Share.</div>
              </div>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>
<script>

import TheFooter from "@/containers/TheFooter.vue";

export default {
  name: "OnBoarding" ,
  components: {TheFooter},
  props: {
    maxContentWidth: {
      type: Number,
      default: 390
    }
  }
}
</script>


<style scoped>

</style>