<template>
  <CFooter :fixed="false">
    <CRow class="w-100">
      <CCol class="text-center"> &copy; {{ new Date().getFullYear() }} Pica Group S.p.A. | VAT no. 02529950392 | <span v-translate>Fully-paid share capital €80,557</span>
        | <span v-translate>Patent-protected technology</span></CCol>
    </CRow>


  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
